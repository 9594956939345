































import { ReadingMaterialFileType } from '@/utils/constants';
import { ToastProgrammatic } from 'buefy';
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class ReadingMaterialUpload extends Vue {
  currentFile: File[] = [];
  isFileSizeValid = true;
  isFileTypeValid = true;
  previousFileUrl = '';

  getImageUrl() {
    let imageSrc = ``;
    const splits = this.currentFile.length > 0 ? this.currentFile[0].name.split('.') : '';
    const fileExtension = splits[splits.length - 1];
    switch (fileExtension) {
      case ReadingMaterialFileType.pptx: {
        imageSrc = `powerpoint.svg`;
        break;
      }
      case ReadingMaterialFileType.xlsx: {
        imageSrc = `ic_excel.svg`;
        break;
      }
      case ReadingMaterialFileType.doc: {
        imageSrc = `ic_word.svg`;
        break;
      }
      case ReadingMaterialFileType.docx: {
        imageSrc = `ic_word.svg`;
        break;
      }
      case ReadingMaterialFileType.pdf: {
        imageSrc = `ic_pdf.svg`;
        break;
      }
      default: {
        imageSrc = `ic_img.svg`;
        break;
      }
    }

    return require(`@/assets/icons/${imageSrc}`);
  }

  get currentFileName() {
    var name = ``;
    var size = 0;
    if(this.currentFile.length > 0) {
      name = this.currentFile.length + " ";
      name += this.currentFile.length > 1? "files attached.": "file attached.";
      this.currentFile.forEach((item) => {
        size += item.size;
      });
      name += "\n Size: " + (size/1000000) + " MB";
    }
    return name;
  }

  @Prop({ required: true })
  fileTypes!: string[];

  @Prop()
  previousSelectedFile!: string;

  @Watch(`previousSelectedFile`)
  updateCurrentFile() {
    if (this.previousSelectedFile.length > 0) {
      this.previousFileUrl = this.previousSelectedFile;
    }
  }

  checkAttachment(data: any[]) {
    this.isFileSizeValid = true;
    this.isFileTypeValid = true;

    this.currentFile = [];
    data.forEach((item) => {
      if (!this.fileTypes.includes(item.type)) {
        ToastProgrammatic.open({
          type: 'is-danger',
          duration: 3000,
          message: `Invalid File Type. Expecting Microsoft Word, Microsoft Excel, Microsoft Powerpoint, PDF, JPEG or PNG `,
        });
        this.isFileTypeValid = false;
        this.currentFile = [];
      }
    })
    if (this.isFileTypeValid && this.isFileSizeValid) {
      this.updateAttachment(data);
    }
  }

  @Emit(`input`)
  updateAttachment(data: File[]) {
    this.currentFile = data;
    return this.currentFile;
  }
}
