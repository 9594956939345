







































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import { DropdownItem } from '@/models/dropdown';
import ReadingMaterialBatchUpload from '@/components/resources/ReadingMaterialBatchUpload.vue';
import ReadingMaterialUpload from '@/components/resources/ReadingMaterialUpload.vue';
import { CreateReadingMaterial, UpdateReadingMaterial } from '@/models/reading_material';
@Component({
  components: { Dropdown, ReadingMaterialBatchUpload, ReadingMaterialUpload },
})
export default class ReadingMaterialFormTemplate extends Vue {
  // UI State
  selectedCourseText = `- Select -`;
  selectedFolderText = `- Select -`;

  // Values
  isAvailable = true;
  currentMaterialName = ``;
  currentMaterialLevelName = ``;
  currentDropdownItem: DropdownItem = {
    text: ``,
    value: ``,
    uniqueKey: ``,
  };
  currentFolderDropdownItem: DropdownItem = {
    text: ``,
    value: ``,
    uniqueKey: ``,
  };
  selectedReadingMaterial: File | null = null;
  selectedReadingMaterials: File[] = [] ;
  selectedThumbnailMaterial: File | null = null;

  // Edit Variables
  previousSelectedCourseValue = ``;
  previousSelectedFolderValue = ``;
  previousSelectedMaterialFile = ``;
  previousSelectedThumbnailMaterialFile = ``;

  @Prop({})
  isEditMode!: boolean | false;

  @Prop({})
  resourceCourses!: DropdownItem[];

  @Prop({})
  folderNames!: DropdownItem[];

  @Prop()
  readingMaterialCoursePrefixId!: number;

  @Prop()
  readingMaterialFolderName!: string;

  get courses() {
    return this.resourceCourses;
  }

  get folders() {
    return this.folderNames;
  }

  get materialLevelPlaceholder() {
    return `Folder Name`;
  }

  get uploadFileTypes() {
    return [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
      'application/msword', // doc
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
      'application/pdf', //pdf
      'image/png', //png
      'image/jpeg', //jpeg
    ];
  }

  get isSaveButtonEnabled() {
    if(this.isEditMode) {
      return (
        this.selectedCourseText.length > 0 &&
        this.selectedThumbnailMaterial != null
      );
    }
    return (
      this.selectedCourseText.length > 0 &&
      this.currentDropdownItem.value.length > 0 &&
      (this.currentMaterialLevelName.length > 0 ||
        this.currentFolderDropdownItem.value.length > 0) &&
      this.currentMaterialName.length > 0 &&
      this.selectedReadingMaterials.length > 0
    );
  }

  @Watch(`readingMaterialCoursePrefixId`)
  autoFillCourse() {
    if (this.readingMaterialCoursePrefixId) {
      this.previousSelectedCourseValue = this.readingMaterialCoursePrefixId.toString();
    }
  }

  @Watch(`readingMaterialFolderName`)
  autoFillFolderName() {
    this.currentMaterialLevelName = this.readingMaterialFolderName;
  }

  updateSelection(data: DropdownItem) {
    this.selectedCourseText = data.text;
    this.currentDropdownItem = data;
    this.returnSelectedCourseId();
  }

  updateFolderSelection(data: DropdownItem) {
    this.selectedFolderText = data.text;
    this.currentMaterialLevelName = ``;
    this.currentFolderDropdownItem = data;
  }

  updateMaterialLevelText(value: string) {
    this.currentMaterialLevelName = value;
    this.currentFolderDropdownItem = {
      text: ``,
      value: ``,
      uniqueKey: ``,
    };
    this.selectedFolderText = `- Select -`;
    this.previousSelectedFolderValue = ``;
  }

  updateMaterialFile(payload: File[]) {
    this.selectedReadingMaterials = payload;
  }

  updateThumbnailMaterialFile(payload: File | null) {
    this.selectedThumbnailMaterial = payload;
  }

  @Emit(`course`)
  returnSelectedCourseId() {
    return this.currentDropdownItem;
  }

  @Emit(`submit`)
  returnReadingMaterialForm() {
    let output: CreateReadingMaterial = {
      name: this.currentMaterialName,
      isAvailable: this.isAvailable,
      courseId: Number(this.currentDropdownItem.value),
      folderName: ``,
      readingMaterials: this.selectedReadingMaterials,
      thumbnailMaterial: this.selectedThumbnailMaterial
    };
    if (this.currentMaterialLevelName.length > 0) {
      output.folderName = this.currentMaterialLevelName;
    }

    if (this.currentFolderDropdownItem.value.length > 0) {
      output.folderName = this.currentFolderDropdownItem.value;
    }
    return output;
  }
}
